<template>
    <div class="flex">
        <div>
            <label class="inline-flex items-center cursor-pointer">
                <input v-model="isConnection" type="checkbox" class="sr-only peer">
                <div class="relative w-12 h-6 bg-blue-200 rounded-full 
                            peer peer-checked:after:translate-x-full 
                            rtl:peer-checked:after:-translate-x-full 
                            peer-checked:after:border-white after:content-[''] 
                            after:absolute after:top-[2px] after:start-[2px] 
                            after:bg-white after:border-gray-300 after:border 
                            after:rounded-full after:h-5 
                            after:w-5 after:transition-all  
                            peer-checked:bg-black">
                </div>
                <span class="ms-3 text-sm font-medium mr-5">SIP</span>
            </label>
        </div>
        <div v-show="isLoading" class="border-gray-200 h-7 w-7 animate-spin rounded-full border-4 border-t-black flex items-center"></div>
    </div>
    <button style="margin-right: 30px;" class="ml" @click="openPhone()" :disabled="!acceptCall"><img src="@/assets/svg/phone.svg" alt=""></button>
    <modal @closeModal="closePhone()" ref="phoneModal" :title="'SIP'">
        <div>
            <div class="flex items-center mt-5 mx-5">
                <input 
                placeholder="Номер телефона" 
                type="text"
                @keyup.enter="call()" 
                v-model.trim="number" 
                class="phone-number w-full"
                >
                <span class="timer" v-if="session && confirmed">
                    {{time}}
                </span>
            </div>
            <hr>
            <div class="flex justify-center mt-3" v-if="session">
                <button @click="muteOrUmmute()" class="flex py-[8px] px-[80px] text-center text-[14px] rounded-[10px] session-action">
                    Микрофон
                    <microphone v-if="!isMuted" class="mt-1 ml-5"/>
                    <microphoneOff v-else class="mt-1 ml-5"/>
                </button>
            </div>
            <div class="mt-3 mx-3" v-if="!session">
                <button  @click="call()" class="control-btn rounded-[10px] w-full mt-[10px]">Вызов</button>
            </div>
            <div class="mt-3 mx-3" v-else-if="session.direction === 'incoming' && confirmed !== false" >
                <button class="control-btn w-full rounded-[10px]" @click="acceptCallCliens()">Принять</button>
                <button class="control-btn hangup w-full rounded-[10px]" @click.stop="hangup()">Отклонить</button>
            </div>
            <div class="mt-3 mx-3"  @click.stop="hangup()" v-else>
                <button class="control-btn hangup w-full rounded-[10px]" >Завершить</button>
            </div>
        </div>
        <audio ref="audioPlayer" :src="audio"></audio>
    </modal>
</template>

<script>
// import audio from '@/components/sip/audio.mp3'
import microphone from '@/components/icon/microphone.vue'
import microphoneOff from '@/components/icon/microphoneOff.vue'
import modal from '@/components/modal.vue'
import { ref, watch, computed, onMounted } from 'vue';
import JsSIP from 'jssip'
import ringtone from '@/assets/ringtone.wav'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
export default {
    components: {
        modal,
        microphone,
        microphoneOff
    },      
    setup() {
        const isLoading = ref(false)
        const isConnection = ref(false)
        const toast = useToast()
        let socket = null
        let remoteAudio = null
        let ringtoneAudio = null
        let ua = null
        const session = ref(null)
        const number = ref(null)
        const confirmed = ref(null)
        const phoneModal = ref(null)
        const audioPlayer = ref(null)
        const seconds = ref(0)
        const isMuted = ref(false)
        const timer = ref(null)
        const configuration = {
        mediaConstraints: {
          audio: true,
          video: false
        },
        rtcOfferConstraints: {
          offerToReceiveAudio: true,
          offerToReceiveVideo: false
        }
      }

      onMounted(() =>{
        window.addEventListener('keydown', handleKeyDown);
      })

      const handleKeyDown = (event) => {
        const isCtrlPressed = event.ctrlKey || event.metaKey;

        const isMinusPressed = event.keyCode === 109;
        if(isCtrlPressed && isMinusPressed) {
            event.preventDefault();
            hangup()
        }
      }

        const connectionSip = () => {
            isLoading.value = true
            if(filterCheck(JSON.parse(localStorage.getItem('sip')))){
                
                let dataSip = JSON.parse(localStorage.getItem('sip'))
                
                socket = new JsSIP.WebSocketInterface(`wss://${dataSip.serverAddress}${dataSip.serverPort ? ':' + dataSip.serverPort : ''}/ws`)
                remoteAudio = new window.Audio()
                ringtoneAudio = new Audio(ringtone)
                ringtoneAudio.autoplay = false
                ringtoneAudio.loop = 'loop'
                remoteAudio.autoplay = true

                try {
                    ua = new JsSIP.UA({
                        sockets: [socket],
                        display_name: dataSip.userNumber,
                        uri: `${dataSip.userNumber}@${dataSip.serverAddress}`,
                        password: `${dataSip.password}`,
                        session_timers: false
                    })                    
                }
                catch(err){
                    console.error('Error creating JsSIP.UA:', err);
                }
                ua.on('connected', () => {
                    toast.success(`SIP подключается`, {
                        position: 'top-right'
                    });
                })

                ua.on('registered', () => {
                    isLoading.value = false
                    toast.success(`SIP зарегистрирован`, {
                        position: 'top-right'
                    });
                })

                ua.on('disconnected', () => {
                    isConnection.value = false
                    isLoading.value = false
                    toast.error(`SIP отключён`, {
                        position: 'top-right'
                    });
                })
                ua.on('unregistered', () => {
                    isConnection.value = false
                    isLoading.value = false
                    toast.error(`SIP отключён`, {
                        position: 'top-right'
                    });
                })
                ua.on('registrationFailed', () => {
                    isConnection.value = false
                    isLoading.value = false
                    toast.error(`Неправильные данные SIP`, {
                        position: 'top-right'
                    });
                })
                ua.on('newRTCSession', (data) => {
                    session.value = data.session
                    
                    if(data.direction === 'incoming') {
                        openPhone()
                        number.value = data.request.from._uri._user
                        ringtoneAudio.play()
                        session.value.on('accepted', (e) => {})
                        session.value.on('confirmed', (e) => {
                            confirmed.value = true
                            // ringtoneAudioPause() нужно посмотреть
                            timer.value = setInterval(()=>{
                                seconds.value++
                            }, 1000)
                        })
                        data.on('ended', (e) => {
                            session.value = null
                            ringtoneAudioPause()
                            confirmed.value = false
                            number.value = ''
                            clearInterval(timer.value)
                            seconds.value = 0
                        })
                        data.on('failed', (e) => {
                            ringtoneAudioPause()
                            session.value = null
                            confirmed.value = false
                            number.value = ''
                            seconds.value = 0
                            clearInterval(timer.value)
                        })
                    }else {
                        session.value = data.session
                        openPhone()
                    }
                })
                ua.start()
            }else {
                isConnection.value = false
                isLoading.value = false
                toast.error(`Заполните данные SIP!`, {
                    position: 'top-right'
                });
            }
        }

        const ringtoneAudioPause = () => {
            if(ringtoneAudio !== undefined || ringtoneAudio !== null) {
                try {
                    ringtoneAudio.pause();
                    ringtoneAudio.srcObject = null;
                }catch {
                    console.log('Ошибка ringtoneAudio');
                }
            }
        }

        const openPhone = () => {
            phoneModal.value.isOpen = true
        }

        const closePhone = () => {
            phoneModal.value.isOpen = false
        }

        const call = () => {
            if(session.value) return
            
            if(!number.value) return

            const eventHandlers = {
                'progress': () => {
                    session.value.connection.ontrack = (e) => {
                        remoteAudio.srcObject = e.streams[0]
                    };
                },
                'failed': () => {
                    number.value = null
                    session.value = null
                    confirmed.value = false
                    clearInterval(timer.value)
                    seconds.value = 0   
                },
                'ended': () => {
                    number.value = null
                    session.value = null
                    confirmed.value = false
                    clearInterval(timer.value)
                    seconds.value = 0
                },
                'confirmed': () => {
                    confirmed.value = true
                    timer.value = setInterval(()=>{
                        seconds.value += 1
                    }, 1000)
                    playAudio()
                }
            }
            const options = {
                'eventHandlers': eventHandlers,
                'mediaConstraints': {'audio': true, 'video': false}
            }
            session.value = ua.call(number.value, options)
        }

        const playAudio = () => {
            audioPlayer.value.play()
        }

        const muteOrUmmute = () => {
            if(session.value && !session.value.isMuted().audio) {
                session.value.mute({audio: true})
                isMuted.value = true
            }else if(session.value && session.value.isMuted().audio){
                session.value.unmute({audio: true})
                isMuted.value = false
            }
            
        }

        const acceptCallCliens = () => {
            playAudio()
            session.value.answer(configuration)
            session.value.connection.ontrack = (e) => {
                remoteAudio.srcObject = e.streams[0]
                remoteAudio.play()
            }
        }
        
        const hangup = () => {
            // isDisconnection()
            if(session) {
                session.value.terminate()
            }
        }
 
        watch(isConnection, (newValue) => {
            newValue ? connectionSip() : isDisconnection()
        })

        const acceptCall = computed(() => {
            return isConnection.value ? true : false
        })

        const time = computed(() => {
            return parseInt(seconds.value / 60) + ':' + ((seconds.value % 60) < 10 ? '0' + (seconds.value % 60) : seconds.value % 60)
        })

        const isDisconnection = () => {
            closePhone()
            isLoading.value = false
            ua.stop()
            toast.error(`SIP отключен`, {
                position: 'top-right'
            });
        }

        const filterCheck = (data) => {
            if(data) {
                if(data.password !== null && data.serverAddress !== null && data.serverPort !== null  && data.userNumber !== null) {
                    return true
                }else {
                    return false
                }
            }else {
                return false
            }
        }

        return {
            isLoading,
            isConnection,
            openPhone,
            acceptCall,
            acceptCallCliens,
            closePhone,
            phoneModal,
            audioPlayer,
            number,
            call,
            session,
            confirmed,
            time,
            muteOrUmmute,
            isMuted,
            hangup,
        }
    }
}
</script>

<style scoped>
.phone-number{
    padding: 8px;
    border: 1px solid #ffffff;
    border-left: none;
    border-right: none;
}
.phone-number:focus{
    outline: none;
}
.timer{
    top: 20px;
    right: 25px;
}
.session-action{
  outline: none;
  border: none;
  background-color: rgb(215, 215, 215);
  border-radius: 100px;
}
.session-action:active{
  background-color: rgb(200, 200, 200);
}
.control-btn{
    padding: 10px;
    background-color: #5BB85F;
    outline: none;
    border: none;
    color: #fff;
    margin-bottom: 10px;
}

.control-btn:active{
  background-color: #43A047;
}
.control-btn.hangup{
  background-color: #b32121;
}
.control-btn.hangup:active{
  background-color: #b32121a9;
}
</style>