import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'analitics',
    component: () => import('@/pages/analitics/orders/index.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.state.isLoggedIn) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
     path: '/login',
    name: 'login',
    component: () => import('@/pages/auth/login.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.isLoggedIn) {
        next({ name: 'home' })
      } else {
        next()
      }
    },
    meta: {
      requiresHttps: true
    }
  },
  {
    path: '/registerApp',
    name: 'registerApp',
    component: () => import('@/pages/analitics/orders/registerApp.vue')
  },
  {
    path: '/orderApp',
    name: 'orderApp',
    component: () => import('@/pages/analitics/orders/orderApp.vue')
  },
  {
    path: '/performer',
    name: 'performer',
    component: () => import('@/pages/orders/performer/index.vue')
  },
  {
    path: '/passenger',
    name: 'passenger',
    component: () => import('@/pages/orders/passenger/index.vue')
  },
  {
    path: '/topCities',
    name: 'topCities',
    component: () => import('@/pages/analitics/topCities/index.vue')
  },
  {
    path: '/performerProfile',
    name: 'performerProfile',
    component: () => import('@/pages/performerProfile/index.vue')
  },
  {
    path: '/performerCount',
    name: 'performerCount',
    component: () => import('@/pages/analitics/performerCount/index.vue')
  },
  {
    path: '/passengerCount',
    name: 'passengerCount',
    component: () => import('@/pages/analitics/passengerCount/index.vue')
  },
  {
    path: '/dailyVisits',
    name: 'dailyVisits',
    component: () => import('@/pages/analitics/dailyVisits/index.vue')
  },
  {
    path: '/passengerCountOfRepeatTrips',
    name: 'passengerCountOfRepeatTrips',
    component: () => import('@/pages/analitics/countOfRepeatTrips/passengerCountOfRepeatTrips/index.vue')
  },
  {
    path: '/performerCountOfRepeatTrips',
    name: 'performerCountOfRepeatTrips',
    component: () => import('@/pages/analitics/countOfRepeatTrips/performerCountOfRepeatTrips/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
})

export default router
