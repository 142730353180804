import { createStore } from 'vuex'
import { checkAuth, getTokenFromLocalstorage } from '@/auth'

export default createStore({
  state: {
    isLoggedIn: checkAuth(),
    token: getTokenFromLocalstorage(),
    menuOpen: false,
    show: false,
    closeMenu: false,
    Open: false,
    openSettings: false,
    MenuOpen: false,
    selectedSection:'',
    sipActive: false
  },
  getters: {
      isLoggedIn: state => state.isLoggedIn,
      menuOpen: state => state.menuOpen,
      show: state => state.show,
      Open: state => state.Open,
      MenuOpen: state => state.MenuOpen,
      openSettings: state => state.openSettings
  },
  mutations: {
    setIsLoggedIn(state, value) {
      state.isLoggedIn = value
      state.token = value
    },
    setMenuOpen(state, value) {
      state.menuOpen = value;
    },
    setShow(state, value){
      state.show = value
    },
    setOpen(state, value){
      state.Open = value
    },
    setSetting(state, value) {
      state.openSettings = value
    },
    setMenu(state,value){
      state.MenuOpen = value
    },
    setSelectedSection(state, section) {
      state.selectedSection = section;
    },
  },
  actions: {
    login({ commit }) {
      commit('setIsLoggedIn', true)
    },
    logout({ commit }) {
      commit('setIsLoggedIn', false)
    },
    toggleMenu({ commit, state }) {
      const isOpen = !state.menuOpen;
      commit('setMenuOpen', isOpen);
    },
    showMenu({ commit, state }) {
      const isShow = !state.show;
      commit('setShow', isShow);
    },
    MenuOpen({ commit, state }) {
      const MenuOpen = !state.MenuOpen;
      commit('setMenu', MenuOpen);
    },
    openMenu({ commit, state }){
      const open = !state.Open;
      commit('setOpen', open);
    },
    openSetting({commit, state}){
      const openSettings = !state.openSettings
      commit('setSetting', openSettings)
    }
  },
  modules: {
    
  }
})
