<template>
     <div
    v-if="isOpen"
    v-bind="$attrs"
    class="fixed z-[9999] flex justify-center mt-[50px] bg-modal-back w-full left-0 top-0"
    @click="closeModal($event)"
    @mousedown="startDrag($event)"
    @mousemove="drag($event)"
    @mouseup="endDrag"
    @mouseleave="endDrag"
    ref="modal"
    style="cursor: move;"
  >
      <div class="block bg-white z-[10000] rounded-[20px] border-[2px] border-[#D9D9D9]" :class="classes">
        <div class="flex justify-end m-[10px] mb-2">
            <img class="bg-gray-50 cursor-pointer" @click="$emit('closeModal')" src="@/assets/svg/close-line.svg" alt=""></div>
        <div v-if="title" class="block pb-[16px] border-b-[2px] border-[#D9D9D9] px-[20px] text-[26px] font-semibold select-none">
          {{ title }}
        </div>
        <slot></slot>
      </div>
    </div>
</template>

<script>

export default {
props: {
    title: {
    type: String,
    default: ''
    },
    size: {
    type: String,
    default: 'default'
    }
},
data() {
    return {
    isOpen: false,
    dragging: false,
    initialX: 0,
    initialY: 0,
    dragTimeout: null,
    }
},
methods: {
    startDrag(event) {
        this.dragging = true;
        this.initialX = event.clientX - this.$refs.modal.offsetLeft;
        this.initialY = event.clientY - this.$refs.modal.offsetTop;
    },
    drag(event) {
        if (this.dragging) {
            const newX = event.clientX - this.initialX;
            const newY = event.clientY - this.initialY;
            this.$refs.modal.style.left = `${newX}px`;
            this.$refs.modal.style.top = `${newY}px`;
        }
    },
    endDrag() {
        this.dragging = false;
    },
    closeModal(event) {
        if (event.target.classList.contains('modal')) {
            this.isOpen = false;
        }
    },
    openModal() {
        this.isOpen = true;
    }
},
computed: {
    classes () {
            return {
                [`modal--${this.size}`]: this.size,
            }
        },
}
}
</script>

<style scoped>
.modal--md {
    max-width:676px; 
    width: 100%;
}
.modal--sm {
    max-width:442px;
    width:100%;
}
.modal--lg {
    max-width:827px;
    width: 100%;
}
.modal--default {
    max-width:300px;
    width: 100%;
}
</style>