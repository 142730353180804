export function checkAuth() {
    const token = sessionStorage.getItem('token')
    return !!token
  }
export function getTokenFromLocalstorage() {
    if(checkAuth()) {
        return sessionStorage.getItem('token')
    }
}
export function setTokenToLocalstorage(token) {
    sessionStorage.setItem('token', token)
}
export function removeTokenFromLocalstorage() {
    sessionStorage.removeItem('token');
  }